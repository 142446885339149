<template>
    <!-- 账号资料 移动端 -->
    <div class="center">
        <div class="row" 
        @click="langChange(key)" 
        v-for="(item,key,index) in langList" 
        :key="index">
            <div class="left">
                {{item}}
            </div>
            <div class="right check" v-show="lang==key">
				<i class="iconfont2 icon-yes"></i>
            </div>
        </div>
    </div>
</template>
<style lang="stylus" scoped>
	@import 'center.styl';
</style>
<script>
import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
import langList from '@/assets/js/langList'

export default {
    data(){
        return{
            langList:langList
        }
    },
    created(){
        if(this.isMobile==1){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });

            loading.close();
        }else{
            this.$router.push({
                path:'/user'
            })
        }
    },
    computed:{
        ...mapGetters(['account','lang','isMobile']),
    },
    methods:{
        langChange(value) {
            this.$i18n.locale = value;
            // this.lang = value;
            // localStorage.setItem('lang', value);
            this.$store.dispatch('app/setLang',value)
        },
    },
}
</script>
